import {Component, OnInit} from '@angular/core';
import {ClinicalTest} from '../../../data/model/clinicalTest';
import {ClinicalCaseService} from '../../../services/clinical-case.service';

@Component({
  selector: 'app-tests-cliniques',
  templateUrl: './clinical-tests.component.html',
  styleUrls: ['./clinical-tests.component.css']
})
export class ClinicalTestsComponent implements OnInit {
  clinicalTests: ClinicalTest[] = [];
  step = 0; // current expansion pane opened (if one is opened)

  onDelete(clinicalTest: ClinicalTest): void {
    this.clinicalTests = this.clinicalTests.filter(obj => obj !== clinicalTest);
    this.saveData();
  }

  onAdd(): void {
    const test: ClinicalTest = new ClinicalTest(1, '', [], [], [], 10);
    this.clinicalTests.push(test);
    this.saveData();
    console.log(this.step + ' ' + this.clinicalTests.length);
    this.step = this.clinicalTests.length - 1;
  }

  nextStep(): void {
    this.step++;
  }

  prevStep(): void {
    this.step--;
  }

  setStep(idx: number): void {
    this.step = idx;
  }

  constructor(private clinicalCaseService: ClinicalCaseService) {
  }

  ngOnInit(): void {
    this.clinicalTests = this.clinicalCaseService.getClinicalCase().checkUpClinicalTestList;
  }

  saveData(): void {
    this.clinicalCaseService.saveClinicalTests(this.clinicalTests);
  }

}
