import {Component, Inject} from '@angular/core';
import {Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DialogData} from '../play-game/play-game.component';
import {CLINICALCASE} from '../../data/mock/CLINICALCASE';
import {NotesListService} from '../../services/notes-list.service';
import {SelectedOptionService} from '../../services/selected-option.service';
import {TextService} from '../../services/text.service';
import {LogService} from '../../services/log.service';
import {Log} from '../../data/model/log';
import {Timer} from '../../data/model/timer';
import {TimerService} from '../../services/timer.service';
// METHOD 1
import {PlatformLocation} from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  isButtonHomeVisible: boolean;
  isButtonLogOutVisible: boolean;
  isButtonHomeFromTutoVisible: boolean;
  isButtonLogOutFromTutoVisible: boolean;
  title = 'SGP';
  topTitle = ' ';
  private notes: string;
  private selectedDiag;
  private text: string;

  // service logs
  log: Log;
  listLog: Log[] = [this.log];
  // Timer Service
  timer: Timer;
  minutes: number;
  secondes: number;

  // appComponent: AppComponent[];

  constructor(public router: Router, private dialog: MatDialog, private notesService: NotesListService,
              private diagnosticService: SelectedOptionService, private textService: TextService,
              private logService: LogService, private timerService: TimerService,
              // METHOD 1
              private location: PlatformLocation
              /*private trackingService: TrackingService,
              private messageService: MessageService*/) {
// ------------ METHOD 5 ------------
    this.location.onPopState(() => {
      this.dialog.open(PopupForbiddenBackForwardDialogComponent).disableClose = true;
    });
    // ------------ METHOD 4 ------------
    /*location.onPopState(() => {
      alert('Vous ne pouvez pas utiliser ce bouton pour retourner en arrière. Utilisez les boutons ...');
    });*/
// -------
    this.isButtonHomeVisible = false;
    this.isButtonLogOutVisible = false;
    this.isButtonHomeFromTutoVisible = false;
    this.isButtonLogOutFromTutoVisible = false;

    // subscribe to noteService
    this.notesService.cast.subscribe(notes => this.notes = notes);
    // subscribe to diagnostic service
    this.diagnosticService.cast.subscribe(selectedDiag => this.selectedDiag = selectedDiag);
    // subscribe to text service
    this.textService.cast.subscribe(text => this.text = text);
    // subscribe to log service
    this.logService.cast.subscribe(log => this.listLog = log);
    this.logService.castLog.subscribe(log => this.log = log);
    // subscribe timer service
    this.timerService.castTimer.subscribe(timer => this.timer = timer);
    this.timerService.castMinutes.subscribe(minutes => this.minutes = minutes);
    this.timerService.castSecondes.subscribe(secondes => this.secondes = secondes);

  }

  launchReturnHome() {
    this.dialog.open(PopupReturnHomeDialogComponent).disableClose = true;
    localStorage.setItem('cc', JSON.stringify(CLINICALCASE));
    localStorage.removeItem('ccJG');
    localStorage.removeItem('note');
    this.notesService.editList('');
    this.textService.editText('');
    this.logService.editListLogExamen([]);

  }

  launchReturnHomefromTuto() {
    this.dialog.open(PopupHomefromTutoDialogComponent).disableClose = true;
    localStorage.setItem('cc', JSON.stringify(CLINICALCASE));
    localStorage.removeItem('ccJG');
    localStorage.removeItem('note');
    this.notesService.editList('');
    this.textService.editText('');
  }

  launchHome() {
    this.router.navigate(['home']);
    localStorage.setItem('cc', JSON.stringify(CLINICALCASE));
    localStorage.removeItem('ccJG');
    localStorage.removeItem('note');
    this.notesService.editList('');
    this.textService.editText('');
    this.logService.editListLogExamen([]);
  }

  launchLogin() {
    this.router.navigate(['login']);
    localStorage.clear();
    this.notesService.editList('');
    this.textService.editText('');
    this.logService.editListLogExamen([]);
  }

  ShowButtonHome() {
    this.isButtonHomeVisible = true;
  }

  HideButtonHome() {
    this.isButtonHomeVisible = false;
  }

  ShowButtonHomefromTuto() {
    this.isButtonHomeFromTutoVisible = true;
  }

  HideButtonHomefromTuto() {
    this.isButtonHomeFromTutoVisible = false;
  }

  ShowButtonLogOut() {
    this.isButtonLogOutVisible = true;
  }

  HideButtonLogOut() {
    this.isButtonLogOutVisible = false;
  }

  ShowButtonLogOutFromTuto() {
    this.isButtonLogOutFromTutoVisible = true;
  }

  HideButtonLogOutFromTuto() {
    this.isButtonLogOutFromTutoVisible = false;
  }

  /*getAppComponents(): void {
    this.trackingService.getAppComponent()
      .subscribe(appComponents => this.appComponent = appComponents);
  }*/

  launchLogOut() {
    this.dialog.open(PopupLogOutDialogComponent).disableClose = true;
  }

  launchLogOutFromTuto() {
    this.dialog.open(PopupLogOutFromTutoDialogComponent).disableClose = true;
  }

  toggle(event) {
    this.logService.editLog({
      id: event.target.id,
      temps: new Timer(this.timer.getMinutes, this.timer.getSecondes),
    });
    this.listLog.push(this.log);
    console.log(this.listLog);
    this.logService.editListLogExamen(this.listLog);
  }

}

// popup when you log out
@Component({
  selector: 'app-root',
  templateUrl: './PopupLogOutDialog.html',
})

export class PopupLogOutDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<PopupLogOutDialogComponent>, public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
  }

  launchLocalStorage() {
    this.router.navigate(['home']);
    localStorage.setItem('cc', JSON.stringify(CLINICALCASE));
  }
}

// popup when you log out from the tutoriel
@Component({
  selector: 'app-root',
  templateUrl: './PopupLogOutFromTutoDialog.html',
})

export class PopupLogOutFromTutoDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<PopupLogOutFromTutoDialogComponent>, public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
  }
}

// popup when you go to the home page
@Component({
  selector: 'app-root',
  templateUrl: './PopupReturnHomeDialog.html',
})

export class PopupReturnHomeDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<PopupReturnHomeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
  }
}

// popup when you go to the home page from the tutoriel
@Component({
  selector: 'app-root',
  templateUrl: './PopupHomefromTutoDialog.html',
})

export class PopupHomefromTutoDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<PopupHomefromTutoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
  }
}


// popup because the arrows of the browser can't be used so we inform the user
@Component({
  selector: 'app-root',
  templateUrl: './PopupForbiddenBackForwardDialog.html',
})

export class PopupForbiddenBackForwardDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<PopupForbiddenBackForwardDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
  }
}
