import {ClinicalCase} from '../model/clinicalCase';
import {CheckUp} from '../model/checkUp';
import {Diagnostic} from '../model/diagnostic';
import {Care} from '../model/care';
import {Patient} from '../model/patient';
import {Timer} from '../model/timer';
import {ClinicalTest} from '../model/clinicalTest';


export let CLINICALCASE: ClinicalCase[] = [
  {
    idCC: 0,
    idCreator: 'Guillaume',
    name: 'Cheville',
    difficulty: 'Facile',
    fields: 'Musculo squelettique',
    logCorrection: 'A partir des observations visuelles, une demande de radiographie était nécessaire',
    dysfunctionDate: new Date(' Feb 08 2020 '),
    careDate: new Date(' Feb 08 2020 '),
    careContext: 'Un patient âgé de 24 ans vient d\'arriver aux urgences après un traumatisme de la cheville au cours de la pratique de football',

    // liste des imageries
    checkUpImagesList: [new CheckUp(1, 'Radio', ['../../../assets/img/radio-1.jpg', '../../../assets/img/radio-2.jpg'], 'Absence de solution de continuité au niveau osseux. Absence d\'arrachement osseux et d\'incongruence articulaire',
      10),
      new CheckUp(1, 'dynamic', [], 'Demande de radiographie dynamique non disponible pour ce cas',
        10),
      new CheckUp(1, 'scanner', [], 'Demande de scanner non disponible pour ce cas',
        10),
      new CheckUp(1, 'artro', [], 'Demande d\'arthroscanner non disponible pour ce cas',
        10),
      new CheckUp(1, 'IRM', [], 'Demande d\'IRM non disponible pour ce cas',
        10),
      new CheckUp(1, 'scintigraphy', [], 'Demande de scintigraphie non disponible pour ce cas',
        10)],


    // liste des examens Biologiques

    checkUpBiologyList: [new CheckUp(1, 'Biologie', [],
      'Pas d\'examen biologique disponible pour ce cas',
      10)],

    // listes des observations
    checkUpObservationList: [new CheckUp(1, 'Visuelles', ['../../../assets/img/oedeme.jpg'], 'Impotence totale à l\'appui immédiat et à la marche, le patient n\'arrive pas à faire plus de quatre pas',
      10),
      new CheckUp(1, 'Palpatoire', [],
        'Douleur vive exprimée par le patient (EN = 9/10 à la palpation de l\'os naviculaire))',
        10)],

    // liste des test cliniques

    checkUpClinicalTestList: [
      new ClinicalTest(1, 'Tête', [''], [''], ['Pas de test à la tête disponible'], 10),
      new ClinicalTest(1, 'Épaule', [''], [''], ['Pas de test à l\'épaule disponible '], 10),
      new ClinicalTest(1, 'Coude', [''], [''], ['Pas de test au coude disponible'], 10),
      new ClinicalTest(1, 'Avant-bras', [''], [''], ['Pas de test à l\'avant bras disponible'], 10),
      new ClinicalTest(1, 'Poignet', [''], [''], ['Pas de test au poignet disponible'], 10),
      new ClinicalTest(1, 'Main', [''], [''], ['Pas de test à la main disponible'], 10),
      new ClinicalTest(1, 'Cervical', [''], [''], ['Pas de test aux cervicales disponible'], 10),
      new ClinicalTest(1, 'Thoracique', [''], [''], ['Pas de test au thorax disponible'], 10),
      new ClinicalTest(1, 'Lombaire', [''], [''], ['Pas de test aux lombaires disponible'], 10),
      new ClinicalTest(1, 'Bassin', [''], [''], ['Pas de test au bassin disponible'], 10),
      new ClinicalTest(1, 'Hanche', [''], [''], ['Pas de test aux hanches disponible'], 10),
      new ClinicalTest(1, 'Genou', [''], [''], ['Pas de test aux genoux disponible'], 10),
      new ClinicalTest(1, 'Cheville', ['', '', '', '', '', '', '', ''],
        ['Test de Tiroir antérieur en position neutre non réalisable',
          'Test de Tiroir antérieur en flexion plantaire non réalisable',
          'Test de Tiroir antérieur en flexion dorsale non réalisable',
          'Test varus / inversion forcé non réalisable',
          'Test de Hopkinson (Squeeze test) non réalisable',
          'Test de compression en dorsi-flexion non réalisable',
          'Test de Kleiger (en rotation externe) non réalisable',
          'Test valgus /eversion forcée non réalisable'],

        ['Test de Tiroir antérieur en position neutre',
          'Test de Tiroir antérieur en flexion plantaire',
          'Test de Tiroir antérieur en flexion dorsale',
          'Test varus / inversion forcé',
          'Test de Hopkinson (Squeeze test)',
          'Test de compression en dorsi-flexion',
          'Test de Kleiger (en rotation externe)',
          '  Test valgus /eversion forcée'], 10),

      new ClinicalTest(1, 'Pied', [''], [''], ['Pas de test aux pieds disponible'], 10),
    ],

    // diagnostic affichées lors du jeu
    diagnosticGame: new Diagnostic(['Entorse', 'Atteinte du ligament talo fibulaire-postérieur', 'Fracture', 'Atteinte du ligament calcanéo-fibulaire', 'Luxation', 'Atteinte du ligament collatéral médial profond', 'Atteinte de la syndesmose', 'Atteinte du ligament talo fibulaire-antérieur', 'Atteinte du ligament collatéral médial superficiel', ' Je ne sais pas']),
    // solution diagnostic (attention les solution doivent être dans se trouver dans le même ordre que dans les propositions)
    diagnosticSolution: new Diagnostic(['Entorse']),


    // traitements proposées lors du jeu
    careGame: new Care(['Botte platrée ( pendant 3 mois) puis réévaluation par le médecin',
        'Botte platrée (pendant 6 mois) puis réévaluation par le médecin', 'Je ne sais pas'],
      ['Anti-inflammatoire', 'Anti-coagulants', 'Anti-douleurs', 'Je ne sais pas'],
      ['Glace (3 fois/jour)', 'Compression', 'Mobilisation passive',
        'Renforcement des fibulaires infra douloureux', 'Repos total', 'Repos avec élévation de la cheville', 'Mobilisation active en infra douloureux',
        'Je ne sais pas']
      , ['Ligamentoplastie LTFA', 'Ligamentoplastie LTFP', 'Ligamentoplastie LTFA + LTFP', 'Je ne sais pas'],
      []),
    // solution aux traitements
    careSolution: new Care(
      [],
      [],
      ['Glace (3 fois/jour)', 'Compression', 'Mobilisation passive', 'Renforcement des fibulaires infra douloureux',
        'Repos avec élévation de la cheville', 'Mobilisation active en infra douloureux'],
      [],
      []),
    maxGameTime: new Timer(0, 0),
    ListInfoTrauma:
      ['J\'ai voulu faire une tête au foot et en retombant, ' +
      'j\ai senti ma cheville partir, ' +
      'j\'ai entendu un crac et je n\'ai pas pu me relever après'],

    // informations concernant le patient
    patient: new Patient(
      'Je m\'appelle Bob Sleigh et j\'ai 24 ans.',
      'Je suis étudiant et je joue au football depuis 17 ans. J\'aime faire de la randonnée avec mes amis le week-end.',
      'Je vis en appartement au 3ème étage avec ascenseur',
      'Je suis en coloc',
      'J\'ai eu une opération de l\'appendicite. ' +
      'J\'ai eu une rupture du tendon d\'achille gauche en 2016,' +
      ' J\'ai eu une rupture du LCA droit en 2018 et je fais facilement des petites entorses',
      'Si je devais donner une douleur de 0 à 10 je donnerai 5/10 au moment de la chute et maintenant 8/10')

  },

];


