import {Component, OnInit} from '@angular/core';
import {AppComponent} from '../../app/app.component';
import {PlayGameComponent} from '../../play-game/play-game.component';
import {DisplayService} from '../../../services/display.service';
import {TextService} from '../../../services/text.service';
import {DiagnosticService} from '../../../services/diagnostic.service';
import {NgxGalleryService} from '../../../services/ngx-gallery.service';
import {NgxGalleryImage} from 'ngx-gallery';
import {ListService} from '../../../services/list.service';
import {ListButtonsService} from '../../../services/list-buttons.service';
import {CheckUp} from '../../../data/model/checkUp';


@Component({
  selector: 'app-clinical-info-menu',
  templateUrl: './clinical-info-menu.component.html',
  styleUrls: ['./clinical-info-menu.component.css', '../../app/app.component.css']
})
export class ClinicalInfoMenuComponent implements OnInit {
  playGameComp: PlayGameComponent;
  appComp: AppComponent;
  clinicalCase: any = JSON.parse(localStorage.getItem('ccJG'));
// list for ListService
  list: string[];
  // string for url for displayService
  url: string;
  url2: string;
  result: string;

  // string for TextService
  text: string;

  // string for DiagnosticService
  diagnostic: string;

  // table for rxjs
  galleryImages: NgxGalleryImage[];
  /*
    //service log
    log: Log;
    listLog:Log[]=[this.log];
  //Timer Service
    timer: Timer;
    minutes: number;
    secondes: number;
  */

// service listButton
  test: string[];
  results: string[];


  constructor(private playGameComponent: PlayGameComponent,
              private appComponent: AppComponent,
              private displayService: DisplayService,
              private textService: TextService,
              private diagnosticService: DiagnosticService,
              private rxjsService: NgxGalleryService,
              private listService: ListService,
              /*private logService: LogService,
              private timerService: TimerService*/
              private listButtonsService: ListButtonsService) {
    this.playGameComp = playGameComponent;
    this.appComp = appComponent;
  }

  ngOnInit() {
    // subscribe to DisplayService
    this.displayService.castUrl.subscribe(url => this.url = url);
    this.displayService.castResult.subscribe(result => this.result = result);
    this.displayService.castUrl2.subscribe(url2 => this.url2 = url2);

    // subscribe to textService
    this.textService.cast.subscribe(text => this.text = text);


    // subscribe to diagnosticService
    this.rxjsService.cast.subscribe(images => this.galleryImages = images);

    // subscribe to ListService
    this.listService.cast.subscribe(list => this.list = list);
    /*
        //subscribe log service
        this.logService.cast.subscribe(list => this.listLog = list);
        this.logService.castLog.subscribe(log => this.log = log);


        //subscribe timer service
        this.timerService.castTimer.subscribe(timer => this.timer = timer);
        this.timerService.castMinutes.subscribe(minutes => this.minutes = minutes);
        this.timerService.castSecondes.subscribe(secondes => this.secondes = secondes);
    */

    this.listButtonsService.cast.subscribe(item => this.test = item);
    this.listButtonsService.castResult.subscribe(result => this.results = result);
  }


  // launch component text template
  launchPatientInfos() {
    this.textService.editText(this.clinicalCase.patient.InfoPatientList);
  }

  launchActivitiesList() {
    this.textService.editText(this.clinicalCase.patient.ActivitiesList);
  }

  launchPainList() {
    this.textService.editText(this.clinicalCase.patient.PainList);
  }

  launchLivingPlaceList() {
    this.textService.editText(this.clinicalCase.patient.LivingPlaceList);
  }

  launchMedicalHistoryList() {
    this.textService.editText(this.clinicalCase.patient.MedicalHistoryList);
  }

  launchSocialSituationList() {
    this.textService.editText(this.clinicalCase.patient.SocialSituationList);
  }

  launchInfoTrauma() {
    this.textService.editText(this.clinicalCase.ListInfoTrauma);
  }


  // functions to launch display
  launchRadiography() {
    this.displayService.editResult(this.clinicalCase.checkUpImagesList[0].result);
    this.rxjsService.editImages([]);
    for (const j in this.clinicalCase.checkUpImagesList[0].media) {
      this.displayService.editUrl(this.clinicalCase.checkUpImagesList[0].media[j]);
      this.galleryImages.push({
        small: this.url,
        medium: this.url,
        big: this.url,
      }, );
    }
  }

  launchDynamicRadiography() {
    this.displayService.editResult(this.clinicalCase.checkUpImagesList[1].result);
    this.rxjsService.editImages([]);
    for (const j in this.clinicalCase.checkUpImagesList[1].media) {
      this.displayService.editUrl(this.clinicalCase.checkUpImagesList[1].media[j]);
      this.galleryImages.push({
        small: this.url,
        medium: this.url,
        big: this.url,
      }, );
    }
  }

  launchScanner() {
    this.displayService.editResult(this.clinicalCase.checkUpImagesList[2].result);
    this.rxjsService.editImages([]);
    for (const j in this.clinicalCase.checkUpImagesList[2].media) {
      this.displayService.editUrl(this.clinicalCase.checkUpImagesList[2].media[j]);
      this.galleryImages.push({
        small: this.url,
        medium: this.url,
        big: this.url,
      }, );
    }
  }

  launchArthroscanner() {
    this.displayService.editResult(this.clinicalCase.checkUpImagesList[3].result);
    this.rxjsService.editImages([]);
    for (const j in this.clinicalCase.checkUpImagesList[3].media) {
      this.displayService.editUrl(this.clinicalCase.checkUpImagesList[3].media[j]);
      this.galleryImages.push({
        small: this.url,
        medium: this.url,
        big: this.url,
      }, );
    }
  }

  launchIRM() {
    this.displayService.editResult(this.clinicalCase.checkUpImagesList[4].result);
    this.rxjsService.editImages([]);
    for (const j in this.clinicalCase.checkUpImagesList[4].media) {
      this.displayService.editUrl(this.clinicalCase.checkUpImagesList[4].media[j]);
      this.galleryImages.push({
        small: this.url,
        medium: this.url,
        big: this.url,
      }, );
    }
  }

  launchScintigraphy() {
    this.displayService.editResult(this.clinicalCase.checkUpImagesList[5].result);
    this.rxjsService.editImages([]);
    for (const j in this.clinicalCase.checkUpImagesList[5].media) {
      this.displayService.editUrl(this.clinicalCase.checkUpImagesList[5].media[j]);
      this.galleryImages.push({
        small: this.url,
        medium: this.url,
        big: this.url,
      }, );
    }
  }

  launchVisuObservation() {
    this.displayService.editResult(this.clinicalCase.checkUpObservationList[0].result);
    this.rxjsService.editImages([]);
    for (const j in this.clinicalCase.checkUpObservationList[0].media) {
      this.displayService.editUrl(this.clinicalCase.checkUpObservationList[0].media[j]);
      this.galleryImages.push({
        small: this.url,
        medium: this.url,
        big: this.url,
      }, );
    }
  }


  launchComportObservation() {
    this.displayService.editResult(this.clinicalCase.checkUpObservationList[1].result);
    this.rxjsService.editImages([]);
    for (const j in this.clinicalCase.checkUpObservationList[1].media) {
      this.displayService.editUrl(this.clinicalCase.checkUpObservationList[1].media[j]);
      this.galleryImages.push({
        small: this.url,
        medium: this.url,
        big: this.url,
      }, );
    }
  }

  launchBiology() {
    this.displayService.editResult(this.clinicalCase.checkUpBiologyList[0].result);
    this.rxjsService.editImages([]);
    for (const i in this.clinicalCase.checkUpBiologyList) {
      for (const j in this.clinicalCase.checkUpBiologyList[i].media) {
        this.displayService.editUrl(this.clinicalCase.checkUpBiologyList[i].media[j]);
        this.galleryImages.push({
          small: this.url,
          medium: this.url,
          big: this.url,
        }, );
      }
    }
    this.rxjsService.editImages(this.galleryImages);
  }

  launchClinicalTest(type: string) {
    const index = this.findIndexOfTestFromList(this.clinicalCase.checkUpClinicalTestList, type);
    if (index !== -1) {
      if (this.clinicalCase.checkUpClinicalTestList[index].title.length === 1
        && this.clinicalCase.checkUpClinicalTestList[index].title[0] === ''
        && this.clinicalCase.checkUpClinicalTestList[index].result.length === 1
        && this.clinicalCase.checkUpClinicalTestList[index].result[0] === '') {
        this.listButtonsService.editItem(['Pas de test disponible pour cette catégorie']);
        this.listButtonsService.editResult(['']);
      } else {
        this.listButtonsService.editItem(this.clinicalCase.checkUpClinicalTestList[index].title);
        this.listButtonsService.editResult(this.clinicalCase.checkUpClinicalTestList[index].result);
      }
    } else {
      this.listButtonsService.editItem(['Pas de test disponible pour cette catégorie']);
      this.listButtonsService.editResult(['']);
    }
    this.displayService.editResult([]);
    this.rxjsService.editImages([]);
    this.playGameComp.ShowComponentDisplayHide();
    this.playGameComp.ShowComponentListHide();
    this.playGameComp.ShowComponentTextHide();
    this.playGameComp.ShowComponentButtonsVisible();
  }

  findIndexOfTestFromList(array: Array<CheckUp>, valueSearched: string): number {
    for (let i = 0; i < array.length; i++) {
      if (array[i].checkUpType === valueSearched) {
        return i;
      }
    }
    return -1;
  }

}
