import {ClinicalCase} from '../model/clinicalCase';
import {Diagnostic} from '../model/diagnostic';
import {Care} from '../model/care';
import {Patient} from '../model/patient';
import {CheckUp} from '../model/checkUp';
import {ClinicalTest} from '../model/clinicalTest';
import {Timer} from '../model/timer';

export const IDCC = 0;

export const CLINICAL_TESTS_MOCK: Array<ClinicalTest> = [];

export const CHECK_UP_IMAGES_MOCK: Array<CheckUp> =
  [
    {idCC: IDCC, checkUpType: 'Demande de radiographie', media: [], result: '', time: 0, title: ['']},
    {idCC: IDCC, checkUpType: 'Demande de radiographie dynamique', media: [], result: '', time: 0, title: ['']},
    {idCC: IDCC, checkUpType: 'Demande de scanner', media: [], result: '', time: 0, title: ['']},
    {idCC: IDCC, checkUpType: 'Demande d\'arthroscanner', media: [], result: '', time: 0, title: ['']},
    {idCC: IDCC, checkUpType: 'Demande d\'IRM', media: [], result: '', time: 0, title: ['']},
    {idCC: IDCC, checkUpType: 'Demande de scintigraphie', media: [], result: '', time: 0, title: ['']},
  ];

export const CHECK_UP_BIOLOGY_MOCK: Array<CheckUp> =
  [
    {idCC: IDCC, checkUpType: 'Biologie', media: [], result: '', time: 0, title: ['']},
  ];

export const CHECK_UP_OBSERVATION_MOCK: Array<CheckUp> =
  [
    {idCC: IDCC, checkUpType: 'Visuelles', media: [], result: '', time: 0, title: ['']},
    {idCC: IDCC, checkUpType: 'Palpatoire', media: [], result: '', time: 0, title: ['']},
  ];

export const PATIENT_MOCK: Patient = {
    InfoPatientList: '', // informations générales
    ActivitiesList: '', // activités
    LivingPlaceList: '', // lieu de vie
    SocialSituationList: '', // situation sociale
    MedicalHistoryList: '', // historique médical
    PainList: '', // informations sur la douleur
  };

export const CLINICAL_CASE_MOCK: ClinicalCase = {
    idCC: IDCC,
    idCreator: '',
    name: '',
    difficulty: '',
    fields: '',
    dysfunctionDate: new Date(),
    careDate: new Date(),
    careContext: '',
    ListInfoTrauma: [], // informations sur le traumatisme
    checkUpImagesList: CHECK_UP_IMAGES_MOCK, // examens d'imagerie
    checkUpBiologyList: CHECK_UP_BIOLOGY_MOCK, // examens biologiques
    checkUpObservationList: CHECK_UP_OBSERVATION_MOCK, //
    checkUpClinicalTestList: CLINICAL_TESTS_MOCK, // tests cliniques
    diagnosticSolution: new Diagnostic([]), // diagnostics
    careGame: new Care([], [], [], [], []), // traitements
    patient: PATIENT_MOCK, // réaliser l'patient-info

    // not used for creation of clinical case
    diagnosticGame: new Diagnostic([]),
    careSolution: new Care([], [], [], [], []),
    maxGameTime: new Timer(0, 0),
    logCorrection: '',
  };
