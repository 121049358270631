import {Users} from '../model/users';

export const USER: Users[] = [
    {
      id: 1,
      username: 'pnicolas',
      password: 'test123',
      name: 'Nicolas',
      surname: 'Pinsault',
      profession: 'kinésithérapeute',
      question: 'quelle est votre couleur préférée ?',
      answer: 'jaune',
    },
    {
      id: 2,
      username: 'bguillaume',
      password: '123test',
      name: 'Guillaume',
      surname: 'Barrier',
      profession: 'étudiant',
      question: 'quelle est votre couleur préférée ?',
      answer: 'vert',
    },
    {
      id: 3,
      username: 'tannelise',
      password: 'test',
      name: 'Anne-lise',
      surname: 'Teulières',
      profession: 'étudiant',
      question: 'quelle est votre couleur préférée ?',
      answer: 'bleu',
    },
    {
      id: 4,
      username: 'dleo',
      password: 'test',
      name: 'Léo',
      surname: 'Dautun',
      profession: 'étudiant',
      question: 'quelle est votre couleur préférée ?',
      answer: 'vert',
    },
    {
      id: 5,
      username: 'mmarine',
      password: 'test',
      name: 'Marine',
      surname: 'Mauriol',
      profession: 'étudiant',
      question: 'quelle est votre couleur préférée ?',
      answer: 'bleu',
    },
    {
      id: 6,
      username: 'cocoprint',
      password: 'test',
      name: 'Test Utilisateur',
      surname: 'Cocoprint',
      profession: 'utilisateur',
      question: 'quelle est votre couleur préférée ?',
      answer: 'rouge',
    },
  ]
;


