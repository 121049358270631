import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ClinicalTest} from '../../../../data/model/clinicalTest';
import {ClinicalTestsCategory} from '../../../../data/model/clinicalTestsCategory';
import {CLINICALTESTSCATEGORIES} from '../../../../data/mock/CLINICALTESTSCATEGORIES';

@Component({
  selector: 'app-clinical-test-detail',
  templateUrl: './clinical-test-detail.component.html',
  styleUrls: ['./clinical-test-detail.component.css']
})
export class ClinicalTestDetailComponent implements OnInit {
  @Output() inputChanged = new EventEmitter();
  @Input() clinicalTest?: ClinicalTest;
  clinicalTestsCategories: ClinicalTestsCategory[] = CLINICALTESTSCATEGORIES;
  selectedFiles: File[] = [];

  getClinicalTestsCategories(): void {
  }

  constructor() {
  }

  ngOnInit(): void {
    this.getClinicalTestsCategories();
  }

  onFileSelected(event: any): void {
    this.selectedFiles.push(event.target.files[0]);
  }

  changeCheckUpType(checkUpType: string): void {
    this.clinicalTest.checkUpType = checkUpType;
    this.inputChanged.emit();
  }
}
