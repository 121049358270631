import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DiagnosticService {
  private diagnostic = new BehaviorSubject<string>('');
  cast = this.diagnostic.asObservable();

  constructor() {
  }

  editDiagnostic(newDiagnostic) {
    this.diagnostic.next(newDiagnostic);
  }
}
