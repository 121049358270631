import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SelectedOptionService {
  private OptionSelected = new BehaviorSubject<string>('');
  cast = this.OptionSelected.asObservable();

  constructor() {
  }

  editOption(newoptionSelected) {
    this.OptionSelected.next(newoptionSelected);
  }
}
