import {Component, OnInit} from '@angular/core';
import {NotesListService} from '../../../services/notes-list.service';
import {SelectedOptionService} from '../../../services/selected-option.service';
import {DiagnosticService} from '../../../services/diagnostic.service';
import {ListTreatmentService} from '../../../services/list-treatment.service';

@Component({
  selector: 'app-notes-treatment',
  templateUrl: './notes-treatment.component.html',
  styleUrls: ['./notes-treatment.component.css']
})
export class NotesTreatmentComponent implements OnInit {
  clinicalCase: any = JSON.parse(localStorage.getItem('ccJG'));

  constructor(private notesService: NotesListService,
              private optionService: SelectedOptionService,
              private diagnosticService: DiagnosticService,
              private treatmentService: ListTreatmentService
  ) {
  }

  notes: string;
  SelectedOptions: string;
  diagnostic: string;
  treatmentMedical: string[];
  treatmentOrtho: string[];
  treatmentFct: string[];
  treatmentSurgical: string[];
  treatmentOthers: string[];

  ngOnInit() {
    // subscribe to noteService
    this.notesService.cast.subscribe(notes => this.notes = notes);
    this.optionService.cast.subscribe(SelectedOptions => this.SelectedOptions = SelectedOptions);
    this.diagnosticService.cast.subscribe(diagnostic => this.diagnostic = diagnostic);
    this.treatmentService.castMedical.subscribe(treatmentMedical => this.treatmentMedical = treatmentMedical);
    this.treatmentService.castOrtho.subscribe(treatmentOrtho => this.treatmentOrtho = treatmentOrtho);
    this.treatmentService.castfct.subscribe(treatmentfct => this.treatmentFct = treatmentfct);
    this.treatmentService.castSurgical.subscribe(treatmentSurgical => this.treatmentSurgical = treatmentSurgical);
    this.treatmentService.castOthers.subscribe(treatmentOthers => this.treatmentOthers = treatmentOthers);
  }

}
