import {Component, Input, OnInit} from '@angular/core';
import {ClinicalCase} from '../../../data/model/clinicalCase';
import {CLINICAL_CASE_MOCK} from '../../../data/mock/mock-clinical-case';
import {ClinicalCaseService} from '../../../services/clinical-case.service';
import {Diagnostic} from '../../../data/model/diagnostic';

export interface Resume {
  categorie: string;
  infos: any;
}

const RESUME_DATA: Resume[] = [
  {categorie: 'Patient', infos: ''},
  {categorie: 'Examens', infos: ''},
  {categorie: 'Diagnostic', infos: ''},
  {categorie: 'Traitement', infos: ''},
];

@Component({
  selector: 'app-resume',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css']
})
export class SummaryComponent implements OnInit {

  @Input() clinicalCase: ClinicalCase = CLINICAL_CASE_MOCK;
  displayedColumns = ['categorie', 'infos'];
  @Input() dataSource = RESUME_DATA;


  constructor(private ClinicalCaseService: ClinicalCaseService) {

  }

  ngOnInit(): void {
    this.ClinicalCaseService.getClinicalCase();
  }

  getClinicalCase(): any {
    return this.ClinicalCaseService;
  }

}
