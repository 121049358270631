import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DisplayService {
  private url = new BehaviorSubject<string>('');
  private url2 = new BehaviorSubject<string>('');
  private result = new BehaviorSubject<string>('');

  castUrl = this.url.asObservable();
  castUrl2 = this.url2.asObservable();
  castResult = this.result.asObservable();

  constructor() {
  }

  editUrl(newUrl) {
    this.url.next(newUrl);
  }

  editUrl2(newUrl2) {
    this.url2.next(newUrl2);
  }

  editResult(newResult) {
    this.result.next(newResult);
  }
}
