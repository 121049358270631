export class Patient {
  InfoPatientList: string;
  ActivitiesList: string;
  LivingPlaceList: string;
  SocialSituationList: string;
  MedicalHistoryList: string;
  PainList: string;

  constructor(InfoPatientList: string, ActivitiesList: string, LivingPlaceList: string, SocialSituationList: string, MedicalHistoryList: string, PainList: string) {
    this.InfoPatientList = InfoPatientList;
    this.ActivitiesList = ActivitiesList;
    this.LivingPlaceList = LivingPlaceList;
    this.SocialSituationList = SocialSituationList;
    this.MedicalHistoryList = MedicalHistoryList;
    this.PainList = PainList;
  }
}
