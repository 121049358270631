export class Diagnostic {
  diagnosticList: Array<string>;

  constructor(diagnosticList: Array<string>) {
    this.diagnosticList = diagnosticList;
  }

  addDiagnostic(newDiagnostic: string): void {
    this.diagnosticList.push(newDiagnostic);
  }

  removeDiagnostic(diagnostic: string): void {
    this.diagnosticList = this.diagnosticList.filter(obj => obj !== diagnostic);
  }
}
