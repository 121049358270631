import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatTable} from '@angular/material/table';
import {Care} from '../../../data/model/care';
import {ClinicalCaseService} from '../../../services/clinical-case.service';


export interface CareInterface {
  desc: string;
  cat: string;
}

@Component({
  selector: 'app-traitement',
  templateUrl: './care.component.html',
  styleUrls: ['./care.component.css']
})

export class CareComponent implements OnInit {

  displayedColumns = ['cat', 'desc', 'avis', 'supp'];
  @Input() dataSource: CareInterface[] = [];
  careGame: Care = new Care([], [], [], [], []);
  careSolution: Care = new Care([], [], [], [], []);

  @ViewChild(MatTable, {static: true}) table!: MatTable<any>;

  desc = '';
  cat = '';
  errorMsgAddRow = '';
  index: CareInterface;

  errorMsg(): void {
    console.log('error popup');
  }

  addRow(): void {
    if (this.desc.length > 0 && this.cat.length > 0) {
      this.dataSource.push({desc: this.desc, cat: this.cat});
      this.careGame.addElement(this.cat, this.desc);

      this.table.renderRows();
      this.desc = '';
      this.cat = '';
      this.errorMsgAddRow = '';
    } else {
      this.errorMsgAddRow = 'Les champs nom et description doivent être remplis.';
      this.errorMsg();
    }
    this.saveData();
  }

  removeRow(): void {
    this.careGame.removeElement(this.index);
    this.dataSource = this.dataSource.filter(obj => obj !== this.index);
    this.careSolution.removeElement(this.index);  // TBD: TO VERIFY
    this.table.renderRows();
    this.saveData();
  }

  constructor(private clinicalCaseService: ClinicalCaseService) {
  }

  ngOnInit(): void {
    this.careGame = this.clinicalCaseService.getClinicalCase().careGame;
    this.careSolution = this.clinicalCaseService.getClinicalCase().careSolution;

    for (const ttt of this.careGame.orthopedicTreatmentList) {
      this.dataSource.push({cat: 'Orthopédique', desc: ttt});
    }
    for (const ttt of this.careGame.medicalTreatmentList) {
      this.dataSource.push({cat: 'Médical', desc: ttt});
    }
    for (const ttt of this.careGame.functionalTreatmentList) {
      this.dataSource.push({cat: 'Fonctionnel', desc: ttt});
    }
    for (const ttt of this.careGame.surgicalTreatmentList) {
      this.dataSource.push({cat: 'Chirurgical', desc: ttt});
    }
    for (const ttt of this.careGame.otherTreatmentList) {
      this.dataSource.push({cat: 'Autre care', desc: ttt});
    }
  }

  onClick(element: CareInterface): void {
    this.index = element;
  }

  modifyCareSolution(checked: boolean, element: CareInterface): void {
    if (!checked) {
      this.careSolution.addElement(element.cat, element.desc);
    } else {
      this.careSolution.removeElement(element);
    }
    this.saveData();
  }

  saveData(): void {
    this.clinicalCaseService.saveCares(this.careGame, this.careSolution);
  }
}
