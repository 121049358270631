import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FileExamenList} from '../../../data/model/examen';
import {CheckUp} from '../../../data/model/checkUp';
import {ClinicalCaseService} from '../../../services/clinical-case.service';

export interface ExamenPatientCategories {
  category: string;
  description: string;
}

@Component({
  selector: 'app-examen-patient',
  templateUrl: './check-up-observation.component.html',
  styleUrls: ['./check-up-observation.component.css']
})

export class CheckUpObservationComponent implements OnInit {
  checkUpObservationList: CheckUp[] = [new CheckUp(0, 'Visuelle', [], '', 0),
    new CheckUp(0, 'Palpatoire', [], '', 0)];

  constructor(private clinicalCaseService: ClinicalCaseService) {
  }

  // categories to define how many expansion panels will be created and what is their content
  categories: ExamenPatientCategories[] = [];

  selectedFiles: FileExamenList[] = []; // is is 0 for "observations visuelles" and 1 for "examen palpatoire"
  step = 0; // current expansion pane opened (if one is opened)

  @ViewChild('fileInput', {static: false}) el: ElementRef;
  imageUrl: any = '';
  editFile = true;
  removeUpload = false;
  file: File;

  ngOnInit(): void {
    this.categories.push({category: 'Observations visuelles', description: 'Description des observations'});
    this.categories.push({category: 'Examen palpatoire', description: 'Description des résultats de l\'examen'});
    this.checkUpObservationList = this.clinicalCaseService.getClinicalCase().checkUpObservationList;
  }

  setStep(index: number): void {
    this.step = index;
  }

  nextStep(): void {
    this.step++;
  }

  prevStep(): void {
    this.step--;
  }

  onFileSelected(event: any): void {
    if (this.step === 0 || this.step === 1) {
      this.selectedFiles.push({file: event.target.files[0], id: this.step});
    }
  }

  saveData(): void {
    this.clinicalCaseService.saveObservationCheckUp(this.checkUpObservationList);
  }

  stepHasFile(): boolean {
    if (this.selectedFiles.length > 0) {
      for (const file of this.selectedFiles) {
        if (file.id === this.step) {
          return true;
        }
      }
    }
    return false;
  }
}
