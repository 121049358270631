import {Component, OnInit} from '@angular/core';
import {NgxGalleryImage, NgxGalleryOptions} from 'ngx-gallery';
import {ListButtonsService} from '../../../services/list-buttons.service';
import {DisplayService} from '../../../services/display.service';
import {NgxGalleryService} from '../../../services/ngx-gallery.service';
import {AppComponent} from '../../app/app.component';

@Component({
  selector: 'app-template-button',
  templateUrl: './template-button.component.html',
  styleUrls: ['./template-button.component.css', '../../app/app.component.css']
})
export class TemplateButtonComponent implements OnInit {
  test: string[];
  results: string[];
  clinicalCase: any = JSON.parse(localStorage.getItem('ccJG'));
  appComp: AppComponent;
  // string for url for displayService
  url: string;
  panelOpenState = false;
  // table for rxjs
  galleryImages: NgxGalleryImage[ ];
  galleryOptions: NgxGalleryOptions[];

  // boolean
  isComponentVisibleDsiplay: boolean;

  constructor(private listButtonsService: ListButtonsService, private displayService: DisplayService,
              private rxjsService: NgxGalleryService, ) {
    this.isComponentVisibleDsiplay = false;
  }

  ngOnInit() {
    this.listButtonsService.cast.subscribe(item => this.test = item);
    this.listButtonsService.castResult.subscribe(result => this.results = result);


    // subscribe to diagnosticService
    this.rxjsService.cast.subscribe(images => this.galleryImages = images);
    this.displayService.castUrl.subscribe(url => this.url = url);

  }

  launchDisplay(event) {
    this.isComponentVisibleDsiplay = true;
    for (let i = 0; i < this.clinicalCase.checkUpClinicalTestList.length; i++) {
      for (let j = 0; j < this.clinicalCase.checkUpClinicalTestList.length; j++) {
        if (event === this.clinicalCase.checkUpClinicalTestList[i].title[j]) {
          this.displayService.editResult(this.clinicalCase.checkUpClinicalTestList[i].result[j]);
          this.displayService.editUrl(this.clinicalCase.checkUpClinicalTestList[i].media[j]);
          this.galleryImages = [{
            small: this.url,
            medium: this.url,
            big: this.url,
          }];
          this.rxjsService.editImages(this.galleryImages);

        }
      }
    }
    console.log(this.galleryImages[0]);
  }

}

