import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Diagnostic} from '../../../data/model/diagnostic';
import {MatTable} from '@angular/material/table';
import {ClinicalCaseService} from '../../../services/clinical-case.service';

@Component({
  selector: 'app-diagnostic',
  templateUrl: './diagnostic.component.html',
  styleUrls: ['./diagnostic.component.css']
})
export class DiagnosticComponent implements OnInit {

  @Input() diagnostic = '';
  displayedColumns = ['diagnostic', 'avis', 'supp'];
  diagnosticBoxChecked: Diagnostic = new Diagnostic([]);
  diagnosticGame: Diagnostic = new Diagnostic([]);
  diagnosticSolution: Diagnostic = new Diagnostic([]);
  medicalReorientation = false;
  @ViewChild(MatTable, {static: true}) table!: MatTable<any>;
  errorMsgAddRow = '';
  logCorrection = '';

  constructor(private clinicalCaseService: ClinicalCaseService) {
  }

  ngOnInit(): void {
    this.diagnosticGame = this.clinicalCaseService.getClinicalCase().diagnosticGame;
    this.diagnosticSolution = this.clinicalCaseService.getClinicalCase().diagnosticSolution;
    this.diagnosticBoxChecked = this.clinicalCaseService.getClinicalCase().diagnosticSolution;
    this.logCorrection = this.clinicalCaseService.getClinicalCase().logCorrection;
  }

  addRow(): void {
    if (this.diagnostic.length > 0) {
      this.diagnosticGame.addDiagnostic(this.diagnostic);
      this.table.renderRows();
      this.diagnostic = '';
      this.errorMsgAddRow = '';
    } else {
      this.errorMsgAddRow = 'Le champ description doit être rempli.';
    }
    this.saveData();
  }

  removeRow(index: number): void {
    this.diagnosticGame.diagnosticList.splice(index, 1);
    this.diagnosticSolution.diagnosticList.splice(index, 1);
    this.table.renderRows();
    this.saveData();
  }


  modifyDiagnosticSolution(checked: boolean, diags: string): void {
    if (!checked) {
      this.diagnosticBoxChecked.addDiagnostic(diags);
    } else {
      this.diagnosticBoxChecked.removeDiagnostic(diags);
    }
    this.saveData();
  }

  saveData(): void {
    this.clinicalCaseService.saveDiagnostics(this.diagnosticGame, this.diagnosticSolution, this.logCorrection);
  }

  onReorientationSelected(checked: boolean): void {
    if (checked) {
      this.medicalReorientation = false;
      this.diagnosticSolution = this.diagnosticBoxChecked;
    } else {
      this.medicalReorientation = true;
      this.diagnosticSolution = new Diagnostic(['Réorientation immédiate']);
    }
    this.saveData();
  }
}
