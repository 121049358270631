import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ListButtonsService {
  private item = new BehaviorSubject<[string]>(['']);
  private result = new BehaviorSubject<[string]>(['']);
  cast = this.item.asObservable();
  castResult = this.result.asObservable();

  constructor() {
  }

  editItem(newItem) {
    this.item.next(newItem);
  }

  editResult(newResult) {
    this.result.next(newResult);
  }


}
