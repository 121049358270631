import {Component, OnInit} from '@angular/core';
import {Patient} from '../../../data/model/patient';
import {ClinicalCaseService} from '../../../services/clinical-case.service';

@Component({
  selector: 'app-interrogatoire',
  templateUrl: './patient-info.component.html',
  styleUrls: ['./patient-info.component.css']
})
export class PatientInfoComponent implements OnInit {

  patient: Patient = new Patient('', '', '', '', '', '');
  ListInfoTrauma: string[] = [];
  step = -1;

  constructor(private clinicalCaseService: ClinicalCaseService) {
  }

  ngOnInit(): void {
    this.patient = this.clinicalCaseService.getClinicalCase().patient;
    this.ListInfoTrauma = this.clinicalCaseService.getClinicalCase().ListInfoTrauma;
  }

  setStep(index: number): void {
    this.step = index;
  }

  nextStep(): void {
    this.step++;
  }

  prevStep(): void {
    this.step--;
  }

  saveData(): void {
    this.clinicalCaseService.savePersonalInfo(this.patient, this.ListInfoTrauma);
  }
}
