import {Component, Input, OnInit} from '@angular/core';
import {FileExamenList} from '../../../data/model/examen';
import {CheckUp} from '../../../data/model/checkUp';
import {ClinicalCaseService} from '../../../services/clinical-case.service';

@Component({
  selector: 'app-check-up-biology',
  templateUrl: './check-up-biology.component.html',
  styleUrls: ['./check-up-biology.component.css']
})
export class CheckUpBiologyComponent implements OnInit {

  selectedFiles: FileExamenList[] = [];
  @Input() checkUps: CheckUp[] = [new CheckUp(0, 'Biologique', [], '', 0)];

  constructor(private clinicalCaseService: ClinicalCaseService) {
  }

  ngOnInit(): void {
    this.checkUps = this.clinicalCaseService.getClinicalCase().checkUpBiologyList;
  }

  onFileSelected(event: any): void {
    this.selectedFiles.push({file: event.target.files[0], id: 0});
  }

  validatePage(): void {
    let toWrite = '*****RESUME DE LA PAGE*****\n\n';
    toWrite += this.checkUps[0].checkUpType + '\nDescription :' + this.checkUps[0].result + '\n\n';

    if (this.selectedFiles.length > 0) {
      toWrite += 'Fichiers à télécharger\n';
    }
    for (const file of this.selectedFiles) {
      toWrite += file.file.name + ', appartient à l\'examen dont l\'id (place dans la liste) est : ' + file.id + '\n\n';
    }

    const blob = new Blob([toWrite], {type: 'text/csv'});
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  saveData(): void {
    this.clinicalCaseService.saveCheckUpBiology(this.checkUps);
  }
}
