import {CareInterface} from '../../components/add-game/care/care.component';

export class Care {
  orthopedicTreatmentList: Array<string>;
  medicalTreatmentList: Array<string>;
  functionalTreatmentList: Array<string>;
  surgicalTreatmentList: Array<string>;
  otherTreatmentList: Array<string>;

  constructor(orthopedicTreatmentList: Array<string>, medicalTreatmentList: Array<string>,
              functionalTreatmentList: Array<string>, surgicalTreatmentList: Array<string>, otherTreatmentList: Array<string>) {
    this.orthopedicTreatmentList = orthopedicTreatmentList;
    this.medicalTreatmentList = medicalTreatmentList;
    this.functionalTreatmentList = functionalTreatmentList;
    this.surgicalTreatmentList = surgicalTreatmentList;
    this.otherTreatmentList = otherTreatmentList;
  }

  addElement(cat: string, desc: string): void {
    if (cat === 'Orthopédique') {
      this.orthopedicTreatmentList.push(desc);
    } else if (cat === 'Médical') {
      this.medicalTreatmentList.push(desc);
    } else if (cat === 'Fonctionnel') {
      this.functionalTreatmentList.push(desc);
    } else if (cat === 'Chirurgical') {
      this.surgicalTreatmentList.push(desc);
    } else if (cat === 'Autre care') {
      this.otherTreatmentList.push(desc);
    }
  }

  removeElement(treatment: CareInterface): void {
    if (treatment.cat === 'Orthopédique') {
      this.orthopedicTreatmentList = this.orthopedicTreatmentList.filter(obj => obj !== treatment.desc);
    } else if (treatment.cat === 'Médical') {
      this.medicalTreatmentList = this.medicalTreatmentList.filter(obj => obj !== treatment.desc);
    } else if (treatment.cat === 'Fonctionnel') {
      this.functionalTreatmentList = this.functionalTreatmentList.filter(obj => obj !== treatment.desc);
    } else if (treatment.cat === 'Chirurgical') {
      this.surgicalTreatmentList = this.surgicalTreatmentList.filter(obj => obj !== treatment.desc);
    } else if (treatment.cat === 'Autre care') {
      this.otherTreatmentList = this.otherTreatmentList.filter(obj => obj !== treatment.desc);
    }
  }
}
