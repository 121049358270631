import {Component, OnInit} from '@angular/core';
import {NotesListService} from '../../../services/notes-list.service';
import {SelectedOptionService} from '../../../services/selected-option.service';
import {Router} from '@angular/router';
import * as FileSaver from 'file-saver';
import {AppComponent} from '../../app/app.component';

@Component({
  selector: 'app-evaluation',
  templateUrl: './evaluation.component.html',
  styleUrls: ['./evaluation.component.css', '../../app/app.component.css']
})
export class EvaluationComponent implements OnInit {
  notes: string;
  SelectedOptions: string;
  private appComp: AppComponent;
  private total: string;

  classL1 = 'fa fa-star';
  classL2 = 'fa fa-star';
  classL3 = 'fa fa-star';
  classL4 = 'fa fa-star';
  classL5 = 'fa fa-star';

  classD1 = 'fa fa-star';
  classD2 = 'fa fa-star';
  classD3 = 'fa fa-star';
  classD4 = 'fa fa-star';
  classD5 = 'fa fa-star';

  classP1 = 'fa fa-star';
  classP2 = 'fa fa-star';
  classP3 = 'fa fa-star';
  classP4 = 'fa fa-star';
  classP5 = 'fa fa-star';

  constructor(private notesService: NotesListService,
              private diagnosticService: SelectedOptionService,
              private router: Router) {
  }

  ngOnInit() {
    // subscribe to noteService
    this.notesService.cast.subscribe(notes => this.notes = notes);
    this.diagnosticService.cast.subscribe(SelectedOptions => this.SelectedOptions = SelectedOptions);
  }

  launchHome() {
    this.router.navigate(['/home']);
    this.notesService.editList('');
    this.diagnosticService.editOption('');
  }

  launchNotesPertinance(note: string) {
    localStorage.setItem('notesPertinance', JSON.stringify(note));
    this.classP1 = 'fa fa-star';
    this.classP2 = 'fa fa-star';
    this.classP3 = 'fa fa-star';
    this.classP4 = 'fa fa-star';
    this.classP5 = 'fa fa-star';

    if (note == '1') {
      this.classP1 = 'fa fa-star gold';
    } else if (note == '2') {
      this.classP1 = 'fa fa-star gold';
      this.classP2 = 'fa fa-star gold';
    } else if (note == '3') {
      this.classP1 = 'fa fa-star gold';
      this.classP2 = 'fa fa-star gold';
      this.classP3 = 'fa fa-star gold';
    }

    if (note == '4') {
      this.classP1 = 'fa fa-star gold';
      this.classP2 = 'fa fa-star gold';
      this.classP3 = 'fa fa-star gold';
      this.classP4 = 'fa fa-star gold';
    }

    if (note == '5') {
      this.classP1 = 'fa fa-star gold';
      this.classP2 = 'fa fa-star gold';
      this.classP3 = 'fa fa-star gold';
      this.classP4 = 'fa fa-star gold';
      this.classP5 = 'fa fa-star gold';
    }
  }

  launchNotesLudique(note: string) {
    localStorage.setItem('notesLudique', JSON.stringify(note));
    this.classL1 = 'fa fa-star';
    this.classL2 = 'fa fa-star';
    this.classL3 = 'fa fa-star';
    this.classL4 = 'fa fa-star';
    this.classL5 = 'fa fa-star';

    if (note == '1') {
      this.classL1 = 'fa fa-star gold';
    } else if (note == '2') {
      this.classL1 = 'fa fa-star gold';
      this.classL2 = 'fa fa-star gold';
    } else if (note == '3') {
      this.classL1 = 'fa fa-star gold';
      this.classL2 = 'fa fa-star gold';
      this.classL3 = 'fa fa-star gold';
    }

    if (note == '4') {
      this.classL1 = 'fa fa-star gold';
      this.classL2 = 'fa fa-star gold';
      this.classL3 = 'fa fa-star gold';
      this.classL4 = 'fa fa-star gold';
    }

    if (note == '5') {
      this.classL1 = 'fa fa-star gold';
      this.classL2 = 'fa fa-star gold';
      this.classL3 = 'fa fa-star gold';
      this.classL4 = 'fa fa-star gold';
      this.classL5 = 'fa fa-star gold';
    }

  }

  launchNotesDifficulte(note: string) {
    localStorage.setItem('notesDiffiuclte', JSON.stringify(note));
    this.classD1 = 'fa fa-star';
    this.classD2 = 'fa fa-star';
    this.classD3 = 'fa fa-star';
    this.classD4 = 'fa fa-star';
    this.classD5 = 'fa fa-star';

    if (note == '1') {
      this.classD1 = 'fa fa-star gold';
    } else if (note == '2') {
      this.classD1 = 'fa fa-star gold';
      this.classD2 = 'fa fa-star gold';
    } else if (note == '3') {
      this.classD1 = 'fa fa-star gold';
      this.classD2 = 'fa fa-star gold';
      this.classD3 = 'fa fa-star gold';
    }

    if (note == '4') {
      this.classD1 = 'fa fa-star gold';
      this.classD2 = 'fa fa-star gold';
      this.classD3 = 'fa fa-star gold';
      this.classD4 = 'fa fa-star gold';
    }

    if (note == '5') {
      this.classD1 = 'fa fa-star gold';
      this.classD2 = 'fa fa-star gold';
      this.classD3 = 'fa fa-star gold';
      this.classD4 = 'fa fa-star gold';
      this.classD5 = 'fa fa-star gold';
    }
  }

  saveLog() {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    let yyyy = today.getFullYear();
    let curHour = today.getHours();
    let curMinute = today.getMinutes();
    let curSeconds = today.getSeconds();


    this.total = localStorage.getItem('log') + 'Pertinance :' + localStorage.getItem('notesPertinance')
      + 'Ludique :' + localStorage.getItem('notesLudique') + 'Difficulté:' + localStorage.getItem('notesDiffiuclte');

    let data = new Blob([this.total], {type: 'JSON/plain;charset=utf-8'});
    FileSaver.saveAs(data, 'log' + mm + '/' + dd + '/' + yyyy + '/' + curHour + '/' + curMinute + '/' + curSeconds + '.JSON');

  }
}
