import {Component, OnInit} from '@angular/core';
import {ListService} from '../../../services/list.service';
import {SelectedOptionService} from '../../../services/selected-option.service';
import {ListTreatmentService} from '../../../services/list-treatment.service';


@Component({
  selector: 'app-template-list',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.css']
})
export class TemplateListComponent implements OnInit {
  listOptions: string[];
  // string for notes
  notes: string;
  selectedDiag: string;
  selectedOptions: string[];
  listSelectedmedic: string[];
  listSelectedortho: string[];
  listSelectedfct: string[];
  listSelectedSurgical: string[];
  listSelectedothers: string[];


  treatmentMedical: string[];
  treatmentOrtho: string[];
  treatmentFct: string[];
  treatmentSurgical: string[];
  treatmentOthers: string[];

  type: string;


  constructor(private listService: ListService,
              private diagnosticService: SelectedOptionService,
              private treatmentService: ListTreatmentService) {
  }


  ngOnInit() {
    this.listService.cast.subscribe(list => this.listOptions = list);
    this.listService.castType.subscribe(type => this.type = type);
    this.diagnosticService.cast.subscribe(selectedDiag => this.selectedDiag = selectedDiag);
    this.treatmentService.castMedical.subscribe(treatmentMedical => this.treatmentMedical = treatmentMedical);
    this.treatmentService.castOrtho.subscribe(treatmentOrtho => this.treatmentOrtho = treatmentOrtho);
    this.treatmentService.castfct.subscribe(treatmentfct => this.treatmentFct = treatmentfct);
    this.treatmentService.castSurgical.subscribe(treatmentSurgical => this.treatmentSurgical = treatmentSurgical);
    this.treatmentService.castOthers.subscribe(treatmentOthers => this.treatmentOthers = treatmentOthers);

  }

  launchDiagSelected() {
    this.diagnosticService.editOption(this.selectedOptions);
  }

  launchMedicalTreatment() {
    this.treatmentMedical = this.listSelectedmedic;
    this.treatmentService.editTreatementMedical(this.treatmentMedical);
  }

  launchOrthoTreatment() {
    this.treatmentOrtho = this.listSelectedortho;
    this.treatmentService.editTreatementOrthopedic(this.treatmentOrtho);
  }

  launchFctTreatment() {
    this.treatmentFct = this.listSelectedfct;
    this.treatmentService.editTreatementFct(this.treatmentFct);
  }

  launchSurgicalTreatment() {
    this.treatmentSurgical = this.listSelectedSurgical;
    this.treatmentService.editTreatementSurgical(this.treatmentSurgical);
  }

  launchOthers() {
    this.treatmentOthers = this.listSelectedothers;
    this.treatmentService.editTreatementOthers(this.treatmentOthers);
  }

}
