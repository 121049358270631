import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClinicalCasesService {

  constructor() {
  }

  setClinicalCasesFromFile(content: string): void {
    localStorage.setItem('ccJG', content);
  }
}
