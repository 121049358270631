import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ClinicalCase} from '../data/model/clinicalCase';

@Injectable({
  providedIn: 'root'
})
export class DataBaseService {

  constructor(private httpClient: HttpClient) {
  }

  clinicalCaseUrl = 'http://localhost:3000/clinicalCase';

  getClinicalCase(): Observable<ClinicalCase> {
    return this.httpClient.get<ClinicalCase>(this.clinicalCaseUrl);
  }

  setClinicalCase(clinicalCase: ClinicalCase): Observable<ClinicalCase> {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.httpClient.put<ClinicalCase>(this.clinicalCaseUrl, clinicalCase, httpOptions);
  }
}
