export class ClinicalTest {
  idCC: number;
  checkUpType: string;
  media: string[];
  result: string[];
  time: number;
  title: string[];

  constructor(idCC: number, checkUpType: string, media: string[], result: string[], title: string[], time: number) {
    this.idCC = idCC;
    this.checkUpType = checkUpType;
    this.media = media;
    this.result = result;
    this.title = title;
    this.time = time;

  }
}
