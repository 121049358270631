import {Component, OnInit} from '@angular/core';
import {AppComponent} from '../app/app.component';
import {PopupRulesGameDialogComponent} from '../../components/toolbox/clinical-cases-data-table/clinical-cases-data-table.component';
import {MatDialog} from '@angular/material';
import {FileOpener} from '@ionic-native/file-opener/ngx';
import {ClinicalCasesService} from '../../services/clinical-cases.service';
import {ClinicalCaseService} from '../../services/clinical-case.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css', '../app/app.component.css']
})
export class HomeComponent implements OnInit {
  constructor(public appComponent: AppComponent, private dialog: MatDialog, private fileOpener: FileOpener,
              private clinicalCasesService: ClinicalCasesService) {
    this.appComponent.HideButtonHome();
    this.appComponent.HideButtonHomefromTuto();
    this.appComponent.ShowButtonLogOut();
    this.appComponent.HideButtonLogOutFromTuto();
    this.appComponent.topTitle = ' Accueil';
  }

  ngOnInit() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    window.addEventListener('beforeunload', function(e) {
      let confirmationMessage = '\o/';
      e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
      return confirmationMessage;              // Gecko, WebKit, Chrome <34
    });
  }

  launchGameFromFile(files: FileList): void {
    const file = files[0];
    const fileReader = new FileReader();
    fileReader.readAsText(file, 'UTF-8');
    fileReader.onload = () => {
      if (typeof fileReader.result === 'string') {
        console.log(JSON.parse(fileReader.result));
        this.clinicalCasesService.setClinicalCasesFromFile(fileReader.result);
        this.dialog.open(PopupRulesGameDialogComponent).disableClose = true;
      }
    };
    fileReader.onerror = (error) => {
      console.log(error);
    };
  }
}
