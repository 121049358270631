import {Component, Input, OnInit} from '@angular/core';
import {AppComponent} from '../app/app.component';
import {MenuLeft} from '../../data/model/menuLeft';
import {ClinicalCaseService} from '../../services/clinical-case.service';

@Component({
  selector: 'app-add-game',
  templateUrl: './add-game.component.html',
  styleUrls: ['./add-game.component.css']
})
export class AddGameComponent implements OnInit {

  currentCategory = '';
  @Input() categoriesMenuLeft: MenuLeft[] = [
    {
      name: 'Réaliser l\'patient-info',
      friendlyName: 'Interrogatoire',
      sousCategories: ['Interroger le patient', 'Informations sur le traumatisme', 'Informations sur la douleur']
    },
    {
      name: 'Examiner le patient',
      friendlyName: 'Examen du patient',
      sousCategories: ['Observations visuelles', 'Examen palpatoire']
    },
    {
      name: 'Réaliser des tests cliniques',
      friendlyName: 'Tests cliniques',
      sousCategories: ['Tête', 'Membre supérieur', 'Rachis', 'Membre Inférieur']
    },
    {
      name: 'Demander un examen d\'imagerie',
      friendlyName: 'Examens d\'imagerie',
      sousCategories: ['Demande de radiographie', 'Demande de radiographie dynamique', 'Demande de scanner',
        'Demande d\'arthroscanner', 'Demande d\'IRM', 'Demande de scintigraphie']
    },
    {name: 'Demander un examen biologique', friendlyName: 'Examen biologique', sousCategories: []},
  ];

  constructor(private appComponent: AppComponent, private clinicalCaseService: ClinicalCaseService) {
    this.appComponent.ShowButtonHome();
    this.appComponent.ShowButtonLogOut();
    this.appComponent.HideButtonHomefromTuto();
    this.appComponent.HideButtonLogOutFromTuto();
    this.appComponent.topTitle = ' Création d\'un cas clinique';
  }

  ngOnInit(): void {
  }

  remove(index: number): void {
    this.categoriesMenuLeft.splice(index, 1);
  }

  onCategoryChanged(category: string): void {
    this.currentCategory = category;
  }

  saveClinicalCase(): void {
    this.clinicalCaseService.downloadClinicalCase();
  }

}
