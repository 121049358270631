import {Injectable} from '@angular/core';
import {ClinicalCase} from '../data/model/clinicalCase';
import {DataBaseService} from './data-base.service';
import {
  CHECK_UP_BIOLOGY_MOCK,
  CHECK_UP_IMAGES_MOCK,
  CHECK_UP_OBSERVATION_MOCK,
  CLINICAL_CASE_MOCK,
  CLINICAL_TESTS_MOCK,
  IDCC,
  PATIENT_MOCK
} from '../data/mock/mock-clinical-case';
import {Patient} from '../data/model/patient';
import {CheckUp} from '../data/model/checkUp';
import {ClinicalTest} from '../data/model/clinicalTest';
import {Diagnostic} from '../data/model/diagnostic';
import {Care} from '../data/model/care';
import * as FileSaver from 'file-saver';
import {ClinicalCaseServiceModule} from '../modules/clinical-case-service/clinical-case-service.module';
import {Timer} from "../data/model/timer";

@Injectable({
  providedIn: ClinicalCaseServiceModule
})
export class ClinicalCaseService {

  private clinicalCase: ClinicalCase = CLINICAL_CASE_MOCK;
  private clinicalCaseRetrieved = false;
  private clinicalCaseSaved = false;

  constructor(private dataBaseService: DataBaseService) {
    this.initClinicalCase();
  }

  initClinicalCase(): void {
    this.clinicalCase = {
      idCC: IDCC,
      idCreator: '',
      name: '',
      difficulty: '',
      fields: '',
      dysfunctionDate: new Date(),
      careDate: new Date(),
      careContext: '',
      ListInfoTrauma: [], // informations sur le traumatisme
      checkUpImagesList: CHECK_UP_IMAGES_MOCK, // examens d'imagerie
      checkUpBiologyList: CHECK_UP_BIOLOGY_MOCK, // examens biologiques
      checkUpObservationList: CHECK_UP_OBSERVATION_MOCK, //
      checkUpClinicalTestList: CLINICAL_TESTS_MOCK, // tests cliniques
      diagnosticSolution: new Diagnostic([]), // diagnostics
      careGame: new Care([], [], [], [], []), // traitements
      patient: PATIENT_MOCK, // réaliser l'patient-info

      // not used for creation of clinical case
      diagnosticGame: new Diagnostic([]),
      careSolution: new Care([], [], [], [], []),
      maxGameTime: new Timer(0, 0),
      logCorrection: '',
    };
  }

  downloadClinicalCase(): void {
    const jsonToWrite: string = JSON.stringify(this.clinicalCase, null, '\t');
    const blob = new Blob([jsonToWrite], {type: 'JSON/plain;charset=utf-8'});
    const fileName = this.clinicalCase.name.replace(/ /g, '') !== '' ? this.clinicalCase.name.replace(/ /g, '_') : 'clinicalCase';
    FileSaver.saveAs(blob, fileName + '.JSON');
    this.initClinicalCase();
  }

  saveGeneralInfo(name: string, fields: string, idCreator: string, difficulty: string, careContext: string): void {
    this.clinicalCase.name = name;
    this.clinicalCase.fields = fields;
    this.clinicalCase.idCreator = idCreator;
    this.clinicalCase.difficulty = difficulty;
    this.clinicalCase.careContext = careContext;
  }

  savePersonalInfo(patient: Patient, ListInfoTrauma: string[]): void {
    this.clinicalCase.patient = patient;
    this.clinicalCase.ListInfoTrauma = ListInfoTrauma;
  }

  saveObservationCheckUp(checkUpObservationList: CheckUp[]): void {
    this.clinicalCase.checkUpObservationList = checkUpObservationList;
  }

  saveClinicalTests(clinicalTests: ClinicalTest[]): void {
    this.clinicalCase.checkUpClinicalTestList = clinicalTests;
  }

  saveCheckUpImage(checkUps: CheckUp[]): void {
    this.clinicalCase.checkUpImagesList = checkUps;
  }

  saveCheckUpBiology(checkUps: CheckUp[]): void {
    this.clinicalCase.checkUpBiologyList = checkUps;
  }

  saveDiagnostics(diagnosticGame: Diagnostic, diagnosticSolution: Diagnostic, logCorrection: string): void {
    this.clinicalCase.diagnosticGame = diagnosticGame;
    this.clinicalCase.diagnosticSolution = diagnosticSolution;
    this.clinicalCase.logCorrection = logCorrection;
  }

  saveCares(careGame: Care, careSolution: Care): void {
    this.clinicalCase.careGame = careGame;
    this.clinicalCase.careSolution = careSolution;
  }

  getClinicalCase(): ClinicalCase {
    return this.clinicalCase;
  }


}
